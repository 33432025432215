import { render, staticRenderFns } from "./lvkang.vue?vue&type=template&id=05e47422&scoped=true"
import script from "./lvkang.vue?vue&type=script&lang=js"
export * from "./lvkang.vue?vue&type=script&lang=js"
import style0 from "../../../style/font/font.css?vue&type=style&index=0&id=05e47422&prod&scoped=true&lang=css&external"
import style1 from "./lvkang.vue?vue&type=style&index=1&id=05e47422&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e47422",
  null
  
)

export default component.exports